import React from 'react'

interface Props {

}

const Privacy: React.FC<Props> = () => (
  <div>
    <h1>Privacy Policy</h1>

    <h3>What is <b>Auber?</b></h3>
    <p>Auber is a ride analytics app. It gives you analytics on your uber ride data</p>

    <h3>Uber data usage</h3>
    <p>
      Auber uses OAuth2 for authorization and access user&apos;s ride history data.
      Using which we create do analysis.
    </p>
  </div>
)

export default Privacy
