import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

// import Auth from 'pages/Auth'
import Root from 'pages/Root'
import Privacy from 'pages/Privacy'

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Root />} />
      <Route path="/privacy" element={<Privacy />} />
      {/* <Route path="/auth" element={<Auth />} /> */}
    </Routes>
  </BrowserRouter>
)

export default App
