import React from 'react'

interface Props {

}

const Root: React.FC<Props> = () => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
    <h2>Auber</h2>
    <h4>Uber ride analytics</h4>
    <a href="/privacy">Privacy Policy</a>
  </div>
)

export default Root
